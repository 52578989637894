<template>
  <div id="course-list" class="select-main">
    <div class="div-main">
      <loading :loading="loading" :text="loadingText"></loading>
      <el-form id="formSearch" ref="form" label-width="100px" style="border-bottom: 1px solid #f6f6f6; margin-bottom: 5px; margin-top: 5px;">
        <el-form-item label="图书ID" style="width: 300px; margin-bottom:10px;display: inline-block">
          <el-input type="number"  v-model="id" placeholder="请输入" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="图书名称" style="width: 300px; margin-bottom:10px;display: inline-block">
          <el-input type="text" v-model="name" placeholder="请输入" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="热门课推荐" style="width: 300px; margin-bottom:10px;display: inline-block">
          <el-select v-model="isHotRecommend" placeholder="请选择" style="width:100%">
            <el-option value="true" label="是">是</el-option>
            <el-option value="false" label="否">否</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="免费课推荐" style="width: 300px; margin-bottom:10px;display: inline-block">
          <el-select v-model="isFreeRecommend" placeholder="请选择" style="width:100%">
            <el-option value="true" label="是">是</el-option>
            <el-option value="false" label="否">否</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="APP状态" style="width: 300px; margin-bottom:10px;display: inline-block">
          <el-select v-model="isSale" placeholder="请选择" style="width:100%">
            <el-option value="true" label="已上架">已上架</el-option>
            <el-option value="false" label="未上架">未上架</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="小程序状态" style="width: 300px; margin-bottom:10px;display: inline-block">
          <el-select v-model="isMpSale" placeholder="请选择" style="width:100%">
            <el-option value="true" label="已上架">已上架</el-option>
            <el-option value="false" label="未上架">未上架</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="图书归属" style="width: 300px; margin-bottom:10px;display: inline-block">
          <el-select v-model="branchId" placeholder="请选择" style="width:100%">
            <el-option :value="myBranchId" label="本校图书"></el-option>
            <el-option :value="branchEnum.ZONG_XIAO" label="总校图书"></el-option>
          </el-select>
        </el-form-item>
        <div style="display:inline-block;margin-left: 60px">
          <el-button type="primary" @click="search">搜索</el-button>
          <el-button type="warning" @click="reset">重置</el-button>
        </div>
      </el-form>
      <div id="divList">
        <div class="opt">
          <el-button type="primary" size="small" @click="handleClickAdd">添加</el-button>
        </div>
        <el-table id="tblList" border :data="courses"  style="width: 100%" v-show="tableShow" header-row-class-name="table-header">
          <el-table-column prop="id" label="ID" align="center" width="100"></el-table-column>
          <el-table-column prop="name" label="图书名称" align="left"></el-table-column>
          <el-table-column prop="price" label="价格" align="center" width="100"></el-table-column>
          <el-table-column prop="sort" label="排序" align="center" width="100"></el-table-column>
          <el-table-column label="APP状态" align="center" width="100">
            <template slot-scope="scope">
              {{scope.row.isSale == true ? "已上架" : "未上架"}}
            </template>
          </el-table-column>
          <el-table-column label="小程序状态" align="center" width="100">
            <template slot-scope="scope">
              {{scope.row.isMpSale == true ? "已上架" : "未上架"}}
            </template>
          </el-table-column>
          <el-table-column prop="categoryName" label="所属分类" align="center" width="100"></el-table-column>
          <el-table-column prop="expire" label="有效期至" align="center" width="180"></el-table-column>
          <el-table-column prop="createdOn" label="创建日期" align="center" width="180"></el-table-column>
          <el-table-column fixed="right" label="操作" width="180" align="center">
            <template slot-scope="scope">
              <el-button @click="handleClickQrCode(scope.row)" type="text" size="small" v-show="!isShowUseButton(scope.row)">小程序码</el-button>
              <el-button @click="handleClickUse(scope.row)" type="text" size="small" v-show="isShowUseButton(scope.row)">引用</el-button>
              <el-button @click="handleClickView(scope.row)" type="text" size="small" v-if="isShowViewButton(scope.row)">查看</el-button>
              <el-button @click="handleClickEdit(scope.row)" type="text" size="small" v-show="isShowEditButton(scope.row)">编辑</el-button>
              <el-button @click="handleClickDel(scope.row)" type="text" size="small" v-show="isShowDeleteButton(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination :total="total" :page="page" :size="size" @pageChange="handleCurrentChange" @sizeChange="handleSizeChange"></pagination>
      </div>
      <edit-dialog ref="editDialog"></edit-dialog>
    </div>
  </div>
</template>

<script>
import {
  POP_HEIGHT_BIG,
  request,
  UrlEnum,
  BranchEnum,
  getLocalBranchId,
  TipEnum
} from "../../../../public/js/common-vue";
import Loading from "@/components/Loading";
import Pagination from "@/components/Pagination";
import EditDialog from "@/components/EditDialog";

export default {
  name: "BookList",
  data() {
    return {
      loading: false,
      loadingText:"",
      tableShow:false,
      id:'',
      name:'',
      isFreeRecommend:'',
      isHotRecommend:'',
      isSale:'',
      isMpSale:'',
      branchId:'',
      myBranchId:getLocalBranchId(),
      branchEnum:BranchEnum,
      total:0,
      page:1,
      size:20,
      courses:[]
    }
  },
  methods: {
    handleClickQrCode(row){
      const url = '/course/qrcode?courseId='+row.id;
      this.$refs.editDialog.init('view','360','360','小程序码',url);
    },
    handleClickAdd() {
      const url = "save?categoryId=" + request("categoryId") + "&v=" + new Date();
      this.$refs.editDialog.init('edit', "70%", POP_HEIGHT_BIG, '添加图书', url);
    },
    handleClickView(row){
      const url = "save?id="+row.id;
      this.$refs.editDialog.init('view',"70%",POP_HEIGHT_BIG,'查看图书',url);
    },
    handleClickEdit(row) {
      const url = "save?id=" + row.id;
      this.$refs.editDialog.init('edit', "70%", POP_HEIGHT_BIG, '编辑图书', url);
    },
    handleClickDel(row){
      this.$confirm('确定删除此信息？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.del(row.id);
      }).catch(() => {
        console.log('catch')
      });
    },
    handleCurrentChange(page) {
      this.page = page;
      this.getData();
    },
    handleSizeChange(size) {
      this.size = size;
      this.getData();
    },
    handleClickUse(row){
      this.loadingText = "提交中";
      this.loading=true;
      const url = UrlEnum.COURSE_BRANCH_USE;
      this.$http({
        method: "POST",
        url: url,
        data: {id:row.id,branchId:getLocalBranchId()}
      })
          .then((res) => {
            this.loading=false;
            this.getData();
          })
          .catch((res) => {
            this.loading = false;
            if (res.response != null) {
              const data = res.response.data;
              this.showMsgError(data.message);
            }
            this.tableShow=true;
          })
    },
    isShowViewButton(row){
      if(row.branchId == this.myBranchId){
        return true;
      }
      return false;
    },
    isShowEditButton(row){
      if(row.branchId == this.myBranchId && row.sourceId == 0){
        return true;
      }
      return false;
    },
    isShowDeleteButton(row){
      if(row.branchId == this.myBranchId){
        return true;
      }
      return false;
    },
    isShowUseButton(row){
      if(row.branchId == this.myBranchId){
        return false;
      }
      return true;
    },
    search(){
      this.page=1;
      this.getData();
    },
    makeUrl() {
      let url = UrlEnum.COURSE+"?categoryId="+request("categoryId")+"&page="+this.page+"&size="+this.size;
      if(this.id != ''){
        url += "&id="+this.id;
      }
      if(this.name != ''){
        url += "&name="+this.name;
      }
      if(this.isHotRecommend != ''){
        url += "&isHotRecommend="+this.isHotRecommend;
      }
      if(this.isFreeRecommend != ''){
        url += "&isFreeRecommend="+this.isFreeRecommend;
      }
      if(this.isSale != ''){
        url += "&isSale="+ this.isSale;
      }
      if(this.isMpSale != ''){
        url += "&isMpSale="+this.isMpSale;
      }
      if(this.branchId != ''){
        url += "&branchId="+ this.branchId;
      }
      return url;
    },
    getData() {
      this.loadingText = "加载中";
      this.loading=true;
      const url = this.makeUrl();
      this.$http({
        method: "get",
        url: url,
        data: '',
      })
          .then((res) => {
            this.loading=false;
            this.total = res.data.total;
            this.courses = res.data.list;
            this.tableShow=true;
          })
          .catch((res) => {
            this.loading = false;
            if (res.response != null) {
              const data = res.response.data;
              this.showMsgError(data.message);
            }
            this.tableShow=true;
          })
    },
    reset(){
      this.page=1;
      this.id='';
      this.name='';
      this.isHotRecommend='';
      this.isFreeRecommend='';
      this.isSale='';
      this.isMpSale='';
      this.branchId='';
      this.getData();
    },
    del(id){
      this.loading = true;
      const url = UrlEnum.COURSE +'/'+id;
      this.$http({
        method: "delete",
        url: url,
        data: '',
      })
          .then((res) => {
            this.loading=false;
            this.showMsgSuccess(TipEnum.DELETE_SUCCESS);
            this.getData();
          })
          .catch((res) => {
            this.loading = false;
            if (res.response != null) {
              const data = res.response.data;
              this.showMsgError(data.message);
            }
            this.tableShow=true;
          })
    },
  },
  components:{Loading,Pagination,EditDialog},
  mounted() {
    this.getData();
    const that = this;
    window.addEventListener('message', function (e) {
      if(e.data.key == "refresh"){
        that.$refs.editDialog.hide();
        that.getData();
      }
    }, false)
  }
}
</script>

<style>
.div-main{
  background: white;
  padding:10px 0px;
  height: 100%;
  overflow: auto;
  margin:15px;
}
.select-main{
  height: 100%;
}
.table-header th {
  background-color: #f6f6f6 !important;
}
.el-table td {
  padding: 6px 0 !important;
}
#divList{
  margin:10px;
}
.opt{
  height: 40px;
}
</style>
